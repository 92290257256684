<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7">*温馨提示：请选择成员来进行智能导诊</span>
      </template>
    </van-cell>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group>
        <van-cell
          v-for="item in list"
          :key="item + 'cardl'"
          :title="item.userName"
          :value="item.userID"
          @click="
            onclickselect(
              item.userID,
              item.userName,
              item.userID,
              item.cardNo,
              item.phone,
              item.idCard
            )
          "
        ></van-cell>
      </van-cell-group>
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
    <!-- <div style="text-align:center"><van-image
      style="margin: 45% 35% 3% 35%;"
      width="5rem"
      height="5rem"
      fit="cover"
      :src="newImg"
    />
      </div>
    <div style="font-size: 18px; color: #D3D3D3; width: auto; height: 40px; text-align:center"><span>正在努力建设中</span></div> -->
  </div>
</template>

<script>
import { Toast } from "vant"; //弹出框
import ajax from "../../lib/ajax";
import store from "../../store";

let wxopenid = store.getters["wechat/openid"];
// var lists;
export default {
  data() {
    return {
      userName: "",
      cardNo: "",
      list: [],
      loading: false,
      finished: false,
      newImg: require("../../assets/err/404.png"),
    };
  },

  methods: {
    async onLoad() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
      console.log(wxopenid);
      await ajax
        .get("/Api/CardService/WeChatGetMembers?input=" + wxopenid)
        .then((res) => {
          const lists = res.data.result;
          this.list = lists;
          // console.log(this.list);
        })
        .catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        });
    },
    async onclickselect(userId, userName, outPatientId, cardNo, phone, idCard) {
      store.commit("store/setUserId", userId);
      // alert(outPatientId);
      store.commit("store/setUserName", userName);
      store.commit("store/setPatientid", outPatientId);
      store.commit("store/setCardNo", cardNo);
      store.commit("store/setPhone", phone);
      store.commit("store/setIdcard", idCard);
      this.$router
        .replace({
          path: "/ICharaImg",
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(this.list);
      console.log(wxopenid);
    },
  },
};
</script>

<style>
</style>
